<template>

  <div>
    <div
      class="print-sticker__eight-page"
    >
      <b-table-simple
        v-for="(sTable, sTableIndex) in printTableData"
        :key="sTableIndex"
      >
        <b-tbody>
          <b-tr
            v-for="(trData, trIndex) in sTable"
            :key="trIndex"
          >
            <b-td
              v-for="(sticker, sIndex) in trData"
              :key="'sticker_8'+sIndex"
              style="width: 49.75%; min-width: 49.75%; max-width: 49.75%;"
              :style="[printStickerType === 82 ? {'border' : '10px solid transparent !important'} : {'border': '10px solid #F7F7F7 !important'}]"
            >
              <div
                v-if="sticker.customerName"
                :class="printStickerType === 82 ? 'table-single-sticker__block-size-two' : 'table-single-sticker__block'"
              >
                <b-button
                  v-if="(sticker.paymentStatus && sticker.paymentStatus !== 'Paid')"
                  class="sticker-status__btn"
                >
                  {{ sticker.paymentStatus }}
                </b-button>
                <b-img
                  class="sticker__logo"
                  src="@/assets/images/logo/sticker-logo.png"
                />
                <div class="sticker-text__block">
                  <small>{{ dateFormatWithTime(sticker.date) }}</small>
                  <div class="sticker-text__heading">
                    <h5>{{ sticker.keyName }}</h5>
                  </div>
                  <div class="stock-text__line" />
                  <p>{{ sticker.workFlowName }}</p>
                  <p style="font-size: 8px;">
                    {{ sticker.remarks }}
                  </p>
                  <p>{{ sticker.location }}</p>
                  <p>{{ sticker.customerName }}</p>
                </div>
              </div>
              <div
                v-else
                :class="printStickerType === 82 ? 'table-single-sticker__block-size-two' : 'table-single-sticker__block'"
              />
            </b-td>
            <b-td
              v-if="trData.length < 2"
              style="width: 49.75%; min-width: 49.75%; max-width: 49.75%;"
              :style="[printStickerType === 82 ? {'border' : '10px solid transparent !important'} : {'border': '10px solid #F7F7F7 !important'}]"
            />
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import {
  BTableSimple, BTbody, BTr, BTd, BImg, BButton,
} from 'bootstrap-vue'
import 'array.prototype.move'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stickerType: Number(localStorage.getItem('stickerType') || '8'),
      printStickerType: Number(localStorage.getItem('stickerType') || '8'),
      skipStickerNo: Number(JSON.parse(localStorage.getItem('skipStickerNo')) || 0),
      serviceRequestItems: JSON.parse(localStorage.getItem('serviceRequestItems')) || [],
      serviceRequests: [],
      printStickers: [],
      printStickersArray: [],
      previewStickerData: [],
      kitchenItems: [],
      printTableData: [],
      currentPage: 0,
      totalPage: 0,
      pageLength: 0,
      startIndex: 0,
      endIndex: Number(localStorage.getItem('stickerType') || '8'),
      printDataLength: 0,
      lastPagePrintItem: 0,
      totalTable: 0,
      venueField: '',
      requestField: '',
    }
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem('stickerType')
    localStorage.removeItem('skipStickerNo')
    localStorage.removeItem('serviceRequestItems')
    next()
  },
  created() {
    const formData = new FormData()
    formData.append('srs', JSON.stringify(this.serviceRequestItems))
    this.$http.post('operation/service-requests/kitchen-view/print/preview', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(async response => {
        this.serviceRequests = response.data.serviceRequests
        this.kitchenItems = response.data.kitchenItems
        this.venueField = response.data.venueField ?? ''
        this.requestField = response.data.requestField ?? ''
        this.stickerPrintData()
        await this.$nextTick()
        // window.onafterprint = window.close
        // window.print()
        setTimeout(() => {
          window.print()
        }, 250)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    getLocationFromDescription(data) {
      if (data && data.length) {
        const venue = data.find(o => o.key === this.venueField)
        if (venue && Array.isArray(venue.value) && venue.value.length) {
          return venue.value[0]
        }
      }
      return ''
    },
    getRequestFromDescription(data) {
      if (data && data.length) {
        const request = data.find(o => o.key === this.requestField)
        if (request && request.value) {
          return request.value
        }
      }
      return ''
    },
    stickerPrintData() {
      this.serviceRequests.forEach(srItem => {
        const location = this.getLocationFromDescription(srItem.serviceDescription)
        const remarks = this.getRequestFromDescription(srItem.serviceDescription)
        srItem.serviceDescription.forEach(item => {
          const checkKitchenItem = this.resolveKitchenItems(item)
          if (checkKitchenItem) {
            const stickerObject = {
              date: srItem.date,
              paymentStatus: srItem.paymentStatus,
              keyName: item.key,
              workFlowName: srItem.workFlowName,
              location,
              remarks,
              customerName: srItem.customerName,
            }
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < checkKitchenItem; i++) {
              this.printStickers.push(stickerObject)
            }
          }
        })
      })

      if (this.stickerType === 81 || this.stickerType === 82) { this.stickerType = 8; this.endIndex = 8 }
      if (this.stickerType === 41 || this.stickerType === 42) { this.stickerType = 4; this.endIndex = 4; this.pageSize = 'A4 landscape' }

      this.pageLength = this.printStickers.length
      this.printDataLength = this.printStickers.length + this.skipStickerNo
      this.printDataLastIndex = this.printDataLength - 1
      this.totalPage = Math.ceil((this.pageLength + this.skipStickerNo) / this.stickerType)

      if (this.skipStickerNo > 0) {
        this.endIndex = this.stickerType - this.skipStickerNo
      }
      this.lastPagePrintItem = (this.totalPage * this.stickerType) - this.printDataLength
      this.previewStickerData = this.printStickers.slice(this.startIndex, this.endIndex)
      this.printTableData = []
      this.printStickersArray = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.skipStickerNo; i++) {
        const lastIndex = this.printStickersArray[this.printStickersArray.length - 1]
        if (lastIndex && lastIndex.length < 2) {
          const obj = {}
          this.printStickersArray[this.printStickersArray.length - 1].push(obj)
        } else {
          const objArray = []
          const obj = {}
          objArray.push(obj)
          this.printStickersArray.push(objArray)
        }
      }

      this.printStickers.forEach(item => {
        const lastIndex = this.printStickersArray[this.printStickersArray.length - 1]
        if (lastIndex && lastIndex.length < 2) {
          this.printStickersArray[this.printStickersArray.length - 1].push(item)
        } else {
          const objArray = []
          objArray.push(item)
          this.printStickersArray.push(objArray)
        }
      })

      const chunkSize = 4
      const totalData = this.printStickersArray.length
      for (let i = 0; i < totalData; i += chunkSize) {
        const chunk = this.printStickersArray.slice(i, i + chunkSize)
        this.printTableData.push(chunk)
      }
    },
    resolveKitchenItems(item) {
      const found = this.kitchenItems.find(p => p.id === item.id)
      if (found) {
        let counter = 0
        if (item.type === 'file') {
          if (item.value) {
            /* eslint-disable-next-line no-plusplus */
            counter++
          }
        } else if (item.type === 'title-and-text') {
          if (item.value) {
            /* eslint-disable-next-line no-plusplus */
            counter++
          }
        } else if (item.type === 'short-answer') {
          if (item.value) {
            /* eslint-disable-next-line no-plusplus */
            counter++
          }
        } else if (item.type === 'long-answer') {
          if (item.value) {
            /* eslint-disable-next-line no-plusplus */
            counter++
          }
        } else if (item.type === 'multi-select') {
          if (item.value.length) {
            /* eslint-disable-next-line no-plusplus */
            counter += item.value.length
          }
        } else if (item.type === 'single-select') {
          if (item.value) {
            /* eslint-disable-next-line no-plusplus */
            counter++
          }
        } else if (item.value) {
          /* eslint-disable-next-line no-plusplus */
          counter += item.value
        }

        return counter
      }

      return 0
    },
    changePreview(option) {
      if (option === 'add') {
        this.currentPage += 1
      } else {
        this.currentPage -= 1
      }

      if (this.skipStickerNo > 0) {
        if (this.currentPage > 0) {
          this.startIndex = (this.currentPage * this.stickerType) - this.skipStickerNo
          this.endIndex = this.stickerType + this.startIndex
        }
      } else {
        this.startIndex = this.currentPage * this.stickerType
        this.endIndex = this.stickerType + this.startIndex
      }

      if (this.currentPage === 0) {
        this.startIndex = 0
        this.endIndex = this.stickerType - this.skipStickerNo
      }
      this.previewStickerData = this.printStickers.slice(this.startIndex, this.endIndex)
    },
  },
}
</script>

<style scoped>
    .white-body-background {
      background-color: white;
    }

    .print-sticker__eight-page .table-responsive > table > tbody > tr > td{
      padding: 0 !important;
    }
   table td{
       padding: 0 !important;
   }
</style>
<style lang="scss">
 table td{
       padding: 0 !important;
   }
    body div#app.semi-dark-layout {
        background: #fff !important;
        background-repeat: no-repeat !important;
        background-position: center bottom !important;
        background-size: 100% auto !important;
    }
    body {
        background: #fff !important;
    }
    .print-sticker__eight-page{
    background: #fff;
  .table-single-sticker__block{
    width: 100%;
    height: 332px !important;
    background: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sticker__logo{
        display: block;
        width: 90.072px;
        height: 30.656px;
        margin: 0 auto;
    }
    .sticker-status__btn{
      display: block;
      padding: 3px 5px;
      color: #4B4B4B !important;
      text-align: center;
      font-family: Montserrat;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 8px;
      border-radius: 2px;
      background: rgba(179, 194, 194, 0.50) !important;
      border: none;
      margin: 7px 5px 0 auto;
    }
    .stock-text__line{
      display: block;
      margin: 8px auto;
      width: 36px;
      height: 1.802px;
      background: #000;
    }
    .sticker-text__block{
      margin-top: 8px;
      padding: 0px 12px;
      color: #000;
      text-align: center;
      font-family: Arial;
      small{
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 19px;
      }
      .sticker-text__heading{
        display: flex;
        height: 50.45px;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
          h5{
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 0;
          }
      }
      p{
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 0;
        &:last-of-type{
             text-transform: uppercase;
        }
      }
    }
   }
   .table-single-sticker__block-size-two{
    // width: 492px;
    width: 100%;
    // height: 350px;
    height: 332px !important;
    background: #FFF;
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sticker__logo{
        display: block;
        width: 95.354px;
        height: 35.824px;
        margin: 0 auto;
    }
    .sticker-status__btn{
      display: block;
      padding: 3.178px 5.297px;
      color: #4B4B4B !important;
      text-align: center;
      font-family: Montserrat;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 8.475px;
      border-radius: 2.119px;
      background: rgba(179, 194, 194, 0.50) !important;
      border: none;
      margin: 7px 5px 0 auto;
    }
    .stock-text__line{
      display: block;
      margin: 8.48px auto;
      width: 38px;
      height: 1.909px;
      background: #000;
    }
    .sticker-text__block{
      margin-top: 8.4px;
      padding: 0px 12px;
      color: #000;
      text-align: center;
      font-family: Arial;
      small{
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 19px;
      }
      .sticker-text__heading{
        display: flex;
        height: 53.448px;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
          h5{
            color: #000;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            margin-bottom: 0;
          }
      }
      p{
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        &:last-of-type{
             text-transform: uppercase;
        }
      }
    }
   }
  }
</style>
<style>
    table { page-break-inside:auto; page-break-after:always; width: 100%; height: 100%; padding-top: 5px; }
    table:last-child { page-break-inside:auto; page-break-after:avoid;}
    tr{ page-break-inside:avoid; page-break-after:auto }
    @media print {
        html, body {
            height: 99%;
        }
        @page {
          size: A4;
          margin: 5mm 3mm 5mm 3mm;
        }
        ._hj_feedback_container {
          display: none;
        }
    }
</style>
